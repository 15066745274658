
import { Component, Vue, Prop } from 'vue-property-decorator';
import CertificateForm from '@/components/certificates/CertificateForm.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { Certificate, Role } from '@govflanders/mbp-admin-panel-shared';
import { cloneDeep } from 'lodash';
import ValidationErrorNotification from '@/components/shared/ValidationErrorNotification.vue';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import RoleGuard from '@/utility/auth/RoleGuard.vue';

@Component({
  components: {
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    CertificateForm,
    ValidationErrorNotification,
  },
})
export default class CertificateEdit extends mixins(PendingChangesGuard, RoleGuard) {
  @Prop({
    type: String,
    required: true,
  })
  public id!: string;

  public value: Certificate = null;
  public initialValue: Certificate = null;
  public valueLoading: boolean;
  public error = '';

  private created() {
    this.valueLoading = true;
    this.$store
      .dispatch('certificate/get', this.id)
      .then(certificate => {
        this.value = cloneDeep(certificate);
        this.initialValue = cloneDeep(certificate);
        this.valueLoading = false;
      })
      .catch(e => (this.error = e));
  }

  public revert() {
    this.value = cloneDeep(this.initialValue);
  }

  public save() {
    this.$store
      .dispatch('certificate/update', this.value)
      .then(updatedValue => {
        this.value = updatedValue;
        this.initialValue = cloneDeep(updatedValue);
        this.$toast.success(this.$t('certificates.edit.save-success'));
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('certificates.edit.save-error'));
      });
  }
}
