
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ValidationErrorNotification extends Vue {
  @Prop({
    type: Array,
    required: false,
  })
  public errors: string[];
}
